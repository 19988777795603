import React from 'react';
import { Layout } from 'antd';
import { graphql } from 'gatsby';
import Header from '../../components/PageLayout/Header';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import SEO from '../../components/Seo';
import Comment from '../../components/Comment';
import Config from '../../../config';
import Utils from '../../utils/pageUtils';

import 'prismjs/themes/prism-solarizedlight.css';
import './highlight-syntax.less';
import style from './post.module.less';

const Post = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;
  const {
    title, excerpt, permalink,
  } = frontmatter;

  const canonicalUrl = Utils.resolvePageUrl(
    Config.siteUrl,
    Config.pathPrefix,
    permalink,
  );
  return (
    <Layout className="outerPadding">
      <Layout className="container">
        <SEO
          title={title}
          description={excerpt}
          path={permalink}
          keywords={['Frank', 'Odongkara', 'Jaker', 'Software developer', 'Javascript', 'ReactJS', 'NodeJS', 'IOT', 'Electronics']}
        />
        <Header />
        <SidebarWrapper>
          <div className="marginTopTitle">
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <article className={style.blogArticle} dangerouslySetInnerHTML={{ __html: html }} />
            <Comment pageCanonicalUrl={canonicalUrl} pageId={title} />
          </div>
        </SidebarWrapper>
      </Layout>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($postPath: String!) {
    markdownRemark(frontmatter: { permalink: { eq: $postPath } }) {
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "DD MMM YYYY")
        tag
        permalink
        excerpt
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { permalink: { ne: $postPath } }
        fileAbsolutePath: { regex: "/index.md$/" }
      }
    ) {
      edges {
        node {
          frontmatter {
            permalink
            title
            tag
            excerpt
          }
        }
      }
    }
  }
`;

export default Post;
